<template>
  <div>
    <el-table :data="tableData" :header-cell-style="{
      background: '#F3F4F7',
      color: '#555',
      'font-size': '12px',
    }" :stripe="true" align="center" :border="true" class="report_list">
      <el-table-column prop="class" label="填报批次">
        <template slot-scope="scope">
          <p>{{ batchList[scope.row.batch - 1].name }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="is_wenli" label="课类">
        <template slot-scope="scope">
          <span v-if="scope.row.is_wenli == 1">文科</span>
          <span v-if="scope.row.is_wenli == 2">理科</span>
        </template>
      </el-table-column>
      <el-table-column prop="score" label="成绩" width="80"> </el-table-column>
      <el-table-column prop="ranking" label="位次" width="100">
      </el-table-column>
      <el-table-column label="填报状态" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.record">已填报</span>
          <span v-else>未填报</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="填报时间" width="180">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div v-if="scope.row.record">
            <span class="color1" @click="toVolunteer(scope.row.batch)">查看</span>
            <span class="color1" @click="toReport(scope.row.batch)">导出</span>
            <span class="color1" @click="del(scope.row.id)">删除</span>
          </div>
          <div v-else>
            <span class="color1" @click="toVolunteer(scope.row.batch)">填报</span>
          </div>

        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-button style="float:right;margin-top:20px;margin-right:20px;margin-bottom:30px" size="mini"
        @click="toVolunteer(1)">
        <i class="el-icon-plus"></i>
        新增方案
      </el-button>
    </div>
  </div>
</template>

<script>
//time: 2021/2/9
export default {
  name: "report_list",
  data() {
    return {
      tableData: [],
      batchList: [
        { name: "本科一批", value: 1, id: 1 },
        { name: "本科二批", value: 2, id: 2 },
        { name: "高职高专批", value: 3, id: 3 },
      ],
      is_wenli: "1",
      score: "",
      batch: 1,
      type: 1,
      show: false,
    };
  },
  created() {
    this.score = this.$route.query.score;
    this.is_wenli = this.$route.query.is_wenli;
    this.getList();
  },
  methods: {
    getList() {
      this.$fecth
        .post("report/getReportList", {
          id: this.$route.query.id,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((res) => {
          const { code, msg, data } = res;
          if (code == 200) {
            this.fillTable(data.list)
            this.tableData = data.list;
          } else {
            this.showTips(0, msg);
          }
        });
    },

    fillTable(item) {
      item.forEach((el, i) => {
        if (el.batch != i + 1) {
          item.splice(i, 0, {
            batch: i + 1,
          })
        }
      });

      item.forEach((el, i) => {
        if (el.batch != i + 1) {
          item.splice(i, 0, {
            batch: i + 1,
          })
        }
      });

      console.log(item)

      for (var len = item.length; len < 3; len++) {
        item.push({
          batch: len + 1,
        })
      }
    },

    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
    toReport(batch) {
      var routeData = this.$router.resolve({
        path: "/PdfVolunteer",
        query: {
          is_wenli: this.this.$route.query.is_wenli,
          user_id: this.this.$route.query.id,
          batch: batch,
          score: this.this.$route.query.score,
        },
      });
      window.open(routeData.href, "_blank");
    },
    del(item) {
      this.$fecth
        .post("report/delReport", {
          id: item,
        })
        .then((res) => {
          const { code, msg } = res;
          if (code == 200) {
            this.showTips(1, msg);
            this.getList();
          } else {
            this.showTips(0, msg);
          }
        });
    },
    toVolunteer(batch) {
      this.$router.push({
        path: "/volunteer",
        query: {
          id: this.$route.query.id,
          score: this.$route.query.score,
          is_wenli: this.$route.query.is_wenli,
          batch: batch,
        },
      });
    },
  },
};
</script>

<style lang="less">
.report_list {
  width: 100%;
  margin-top: 20px;

  td {
    padding: 6px 0;
  }

  th {
    padding: 6px 0;
  }
}
</style>

<style lang="less" scoped>
.color {
  color: #278cf8;
}

.color1 {
  color: red;
  margin-left: 20px;
  display: inline-block;
}

.addBtn {
  background: #5b97f9;
  color: white;
  width: 100px;
  padding: 4px 10px;
  margin: 19px 19px 19px 0;
  float: right;
}
</style>