<template>
  <div class="assess">
    <div v-if="tableData">
      <el-table :data="tableData" border style="width: 100%;margin-top:30px" :header-cell-style="{
        background: '#F3F4F7',
        color: '#555',
        'font-size': '12px',
      }">
        <el-table-column type="index" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="id" label="测评ID"></el-table-column>
        <el-table-column prop="name" label="测评项目"></el-table-column>
        <el-table-column prop="result" label="返回ID"></el-table-column>
        <el-table-column prop="create_time" label="时间"></el-table-column>
        <el-table-column prop="edit" label="编辑" width="400">
          <template slot-scope="scope">
            <i class="el-icon-close" @click="handleDel(scope.row.id)" style="margin-left: 20px">删除</i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else style="text-indent: 2rem">该学员未测评。</div>
    <div class="invite" @click="dialogVisible = true">邀请测评</div>
    <el-dialog :visible.sync="dialogVisible" title="测评链接" width="700px">
      <div>
        {{ "https://bkgaoshou.com/mobile/#/user_assess?user_id=" + user_id + '&name=' + name + '&is_wenli=' + is_wenli  }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="copyText">复制</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      tableData: null,
      dialogVisible: false,
      name: "",
      user_id: "",
      is_wenli: "",
    };
  },

  created() {
    this.user_id = this.$route.query.id;
    this.is_wenli = this.$route.query.is_wenli;
    this.name = this.$route.query.name;
    this.$fecth
      .post("Appraisal/getAssess", { user_id: this.user_id })
      .then((res) => {
        this.tableData = res.data;
      });
  },
  methods: {
    handleDel(item) {
      this.$fecth.post("Appraisal/del", { id: item }).then((res) => {
        if (res.code == 200) {
          this.showTips(1, "删除成功");
        }
      });
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
    copyText() {
      let oInput = document.createElement("input"); //创建一个隐藏input（重要！）
      oInput.value =
        "https://bkgaoshou.com/mobile/#/user_assess?user_id=" +
        this.user_id +
        "&name=" +
        this.name +
        "&is_wenli=" +
        this.is_wenli;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      alert("复制成功");
    },
  },
};
</script>

<style scoped lang='less'>
.assess {
  .el-divider--horizontal {
    margin: 6px 0;
  }

  .title {
    color: #5896f9;
    text-indent: 3rem;
  }

  .content_a {
    display: inline-block;
    padding: 0 50px;
  }

  .sp_tab {
    width: 100%;
    padding: 3rem;
    border-collapse: collapse;
    text-align: center;
    margin-top: 10px;

    td {
      width: 16%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border: 1px solid #e3e3e3;
    }
  }
  .bg_img {
    padding: 40px;
    img {
      width: 200px;
      margin-right: 58px;
    }
    img:last-child {
      margin-right: 0;
    }
  }
}

.invite {
  float: right;
  margin-right: 30px;
  background: #5896f9;
  color: white;
  padding: 3px 7px;
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>
