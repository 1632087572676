<template>
  <div class="page">
    <div class="user_info">
      <!-- 用户中心组件 -->
      <el-collapse v-model="activeName">
        <el-collapse-item class="content" name="1">
          <template slot="title">
            <div class="title_collapse">学员测评报告</div>
          </template>
          <Assess />
        </el-collapse-item>

        <el-collapse-item class="content" name="3">
          <template slot="title">
            <div class="title_collapse">学员志愿方案</div>
          </template>
          <report-list />
        </el-collapse-item>

        <div class="main" style="text-align: center">
          <el-button>删除学员信息</el-button>
          <el-button type="primary">发送其他导师</el-button>
        </div>
      </el-collapse>
    </div>
  </div>
</template>

<script>
//time: 2021/1/26
import Assess from "../components/assess";
import ReportList from "../components/ReportList";
// import News from "../components/news";
export default {
  name: "UserInfo",
  components: {
    Assess,
    ReportList,
    // News,
  },
  data() {
    return {
      activeName: ["1", "2", "3", "4"],
    };
  },
  created() { },
};
</script>

<style lang="less" scoped>
* {
  letter-spacing: 1px;
}
</style>

<style lang="less">
.user_info {
  .el-icon-arrow-right:before {
    content: none;
  }

  .content {
    margin-bottom: 10px;
  }

  .title_collapse {
    width: 300px;
    margin: 0 400px;
    letter-spacing: 1px;
    background: #5b97f9;
    text-align: center;
    color: white;
    border-radius: 0 0 100px 100px;
  }

  .user_brief {
    span:first-child {
      color: red;
    }

    span:last-child {
      list-style: disc;
      border: 1px solid #979292;
      padding: 3px;
      color: green;
    }
  }
}
</style>